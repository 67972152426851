@import './styles/settings.scss';

body {
  margin: 0;
  font-family: $text-font;
  background-color: $dark;
  color: white;
  font-weight: 300;

  h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
    font-weight: 300;
  }

  h1 {
    font-size: 4rem;

    @media screen and (max-width: 768px) {
      font-size: 3rem;
      
    }
  }

  h3 {
    font-size: 3rem;
  }

  p {
    font-size: 1.3rem;
  }

  a {
    color: white;
    text-decoration: none;
    font-family: $text-font;
  }
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
