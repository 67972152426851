@import 'src/styles/settings.scss';

.realizations {
  @include pageSection;

  h2 {
    @include h2;
  }

  .realizations-wrapper {
    display: flex;
    flex-direction: column;

    .realization {    
      .realization-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin: 20px 0;

        @media screen and (max-width: 992px) {
          justify-content: center;
          align-items: center;
          flex-direction: column-reverse;
          
        }

        .realization-img {
          width: 350px;
          height: 350px;
          overflow: hidden;
          overflow-y: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;

          img {
            width: 100%;
            cursor: zoom-in;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          }
        }

        .realization-content {
          width: 60%;
        }


      }
    }
  }

  .modal-image {
    img {
      max-width: 750px;
    }
  }

  .dark-theme {
    background-color: $dark;
  }
}