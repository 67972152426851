// GLOBAL SETTINGS
$header-font: "Oswald", sans-serif;
$text-font: "Moderustic", sans-serif;

$dark: #353535ff;
$green: #3c6e71ff;
$white: #ffffffff;
$platinum: #d9d9d9ff;
$blue: #284b63ff;

$opacity: .8;

// MIXINS
@mixin flexCenterRow($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flexCenterColumn($direction: column, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin h2 {
  font-size: 4rem;
  text-align: center;

  span {
    color: $green;
  }
}

@mixin pageSection {
  background-color: $blue;
  margin: 15px 0;
  padding: 35px 0;
}