.footer {
  padding: 35px 0;
  .footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    p {
      font-size: 12px
    }

    img {
      width: 120px;
    }
  }

  .footer-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    a {
      color: white;
      text-decoration: none;
    }
  }
}