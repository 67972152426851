@import 'src/styles/settings.scss';
.banner {
  display: flex;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 150px);
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .shape {
    position: absolute;
    background-color: $green;
    top: 0;
    left: 0;
    width: 500px;
    height: 100%;
    color: $white;
    transform: rotate(90deg);
    z-index: -1;
  }

  .left-section {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: 300;
    }

    span {
      color: $platinum;
    }
  }

}