@import 'src/styles/settings.scss';

.navigation {
  .navigation-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;

    .navigation-logo {
      img {
        width: 165px;
      }
    }

    .navigation-mobile {
      .dropdown {
        position: relative;
        z-index: 3;

        .menu-items {
          border-radius: 5px;
          padding: 25px;
          position: absolute;
          background-color: rgb(0, 0, 0);
          left: -120px;
        }
      }
      span {
        cursor: pointer;
      }
    }

    .navigation-desktop {
      height: 100%;
      display: flex;
      flex-direction: row;
    
      .desktop-bookmark-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;

        span {
          font-family: $text-font;
        }
    
        &::before {
          content: '';
          position: absolute;
          top: -100%;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $green;
          transition: top 0.3s ease-in-out;
          z-index: -1;
        }
    
        &:hover {
          cursor: pointer;
    
          &::before {
            top: 0;
          }
        }
      }
    }
  }
}