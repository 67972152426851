@import 'src/styles/settings.scss';


.shortoffer {
  padding: 50px 0;

  p {
    font-size: 1.5rem;
  }
}

.shortoffer-steps {
  margin-top: 55px;
  padding: 25px 0;
  position: relative;
  background-color: $blue;

  .shortoffer-step {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 34px;
    position: relative;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      gap: 0;
      border-bottom: 1px solid $green;
      justify-content: flex-start;
    }

    .title-line {
      content: '';
      position: absolute;
      display: block;
      width: 1px;
      height: 90%;
      background-color: $green;
      margin-left: 10px;
      top: 90%;
      left: 0;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}