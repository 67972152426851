@import 'src/styles/settings.scss';

.offer {
  h2 {
    @include h2;
  }

  .offer-text {
    padding: 30px 0;
    @include pageSection
  }

  .dark-theme {
    background-color: $dark;
  }
}