@import 'src/styles/settings.scss';

.configurator {
  @include pageSection;
  @include flexCenterColumn;

  h2 {
    @include h2;
  }

  span.config {
    color: $platinum;
    font-weight: bold;
    font-family: $header-font;
  }


}